.go-type {
  font-family: Impact;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: .1rem;
  display: inline-block;
  padding: .6rem .8rem;
  text-transform: uppercase;
  border-radius: .2rem;
  height: auto;

  color: color(white);
  background-color: color(border_grey);

  &--active {
    color: color(primary_grey);
    background-color: color(light_blue);
  }
}

.go-score {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: .02rem;
  display: inline-block;
  padding: .9rem .8rem 1rem .8rem;
  border-radius: .3rem;
  background-color: color(border_grey);
  color: color(white);

  &--win {
    color: color(light_blue);
  }

  &--loss {
    color: color(text_grey);
  }
}

.go-status {
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: .03rem;
  display: inline-block;
  padding: .2rem .9rem .3rem .8rem;
  border-radius: .2rem;
  text-transform: capitalize;

  &--pending {
    color: color(white);
    background-color: color(border_grey);
  }

  &--current {
    color: color(primary_grey);
    background-color: color(light_blue);
  }

  &--upcoming {
    color: color(white);
    background-color: color(primary_grey);
  }

  &--past {
    color: color(text_grey);
    background-color: color(border_grey);
  }
}

.go-request {
  display: inline-block;
  font-family: Impact;
  font-size: 1.6rem;
  line-height: 2rem;
  border-radius: .4rem;
  text-align: center;
  min-width: 3.5rem;
  padding: .8rem .5rem .7rem .6rem;
  letter-spacing: .1rem;

  color: color(white);
  background-color: color(border_grey);

  &--active {
    background-image: radial-gradient(#FF5656, #E7131A);
  }
}

.go-link {
  color: color(white);
  font-size: 1.6rem;
  line-height: 1.9rem;
  letter-spacing: .02rem;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: color(light_blue);
  }
  &--disabled, &--disabled:hover {
    color: color(text_grey);
    cursor: not-allowed;
  }
}
