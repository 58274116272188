@use 'sass:math';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'node_modules/antd/dist/antd';
@import "react-toastify/scss/main.scss";

@import './settings/module';
@import './utilities/module';
@import './generic/module';
@import './objects/module';
@import './components/module';

.transform-component-module_wrapper__SPB86 {
  overflow: visible !important;
}
.tools {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;

  button {
    background-color: rgba(0,0,0,.75);
    outline: 0;
    border: 0;
    color: #fff;
    width: 100%;
    font-size: 2rem;
    padding: 1rem 0;
  }
}
.c-discountcard-image {
  .ant-modal-body {
    position: absolute;
    top: 0;
    padding: 3rem 0;
    height: 90vh;
    .react-transform-wrapper {
      height: 100% !important;
    }
  }
}
