.g-cell {
  &__ticket {
    display: inline-block;

    > div {
      display: flex;
      align-items: center;
      border-radius: .3rem;
      padding: .2rem .4rem;
      background-color: color(border_grey);
      font-size: 1.4rem;
      color: color(white);
      font-weight: 900;
      span {
        transform: translateY(-.13rem);
      }
    }
    &--dark {
      > div {
        background-color: color(primary_grey);
      }
    }
    svg {
      margin-right: .1rem;
    }
  }

  &__fire {
    display: flex;
    > div {
      padding-top: .1rem;
    }
    img {
      margin-right: .6rem;
      height: 2rem;
    }
  }

  &__dash {
    width: 1.2rem;
    margin-left : 1rem;
    border: .05rem solid color(white);
  }
}
