.g-btn {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.4rem;
  color: color(white);
  border: none;
  cursor: pointer;
  outline: none;
  position: relative;

  &:active {
    outline: none;
  }

  &--primary {
    padding: 1.8rem 3rem;
    background-color: color(primary_blue);
    border-radius: 1rem;

    &:hover,
    &:focus,
    &:active {
      background-color: color(dark_blue);
    }

    &--full {
      width: 100%;
    }

    &--sm {
      font-family: Lato;
      font-size: 1.4rem;
      line-height: 2rem;
      letter-spacing: .01rem;
      padding: .6rem 1.2rem;
      border-radius: .3rem;
    }

    &--md {
      padding: 1.3rem 3rem;
    }

    &--slim {
      line-height: 2rem;
      letter-spacing: .02rem;
      padding: 1rem 1.2rem .9rem 1.2rem;
    }

    &--disabled {
      background-color: color(light_grey);
      color: color(dark_grey);
      cursor: not-allowed;

      &:hover,
      &:focus,
      &:active {
        background-color: color(light_grey);
      }

      >.anticon {
        color: white;
      }
    }

    &--loading {
      padding: 1.8rem 4rem 1.8rem 2rem;
    }
  }

  &--secondary {
    border: .1rem solid color(primary_grey);
    padding: 1.8rem 3rem;
    background-color: transparent;
    color: color(second_blue);
    font-weight: bold;
    border-radius: 1rem;

    &:hover {
      background-color: color(lighter_blue);
    }

    &:focus {
      background-color: #E7EFF4;
    }

    &:active {
      color: color(primary_grey);
      background-color: #99BEDD;
    }

    &--disabled {
      border-color: transparent;
      background-color: transparent;
      color: color(dark_grey);
      cursor: not-allowed;

      &:hover {
        box-shadow: none;
        background-color: transparent;
        color: color(dark_grey);
      }
    }
  }

  .ant-spin {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: .8rem;
  }
}
