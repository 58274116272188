.c-coupon {
  width: 100%;
  margin: 0 auto;
  padding: 2.2rem 0;

  @media print {
    width: 90%;
  }

  @include iota-breakpoint(md) {
    width: $search-width;
    padding: 4.6rem 0;
  }

  &__faqs {
    margin-top: 3rem;
    @media print {
      display: none;
    }

    &__title {
      display: block;
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
  }
}

.c-coupon-detail {
  &__back {
    cursor: pointer;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: color(primary_green);
    margin-bottom: 1.2rem;

    >span:last-child {
      margin-bottom: 2.7rem;
      border-bottom: .1rem solid color(primary_green);
      margin-left: 1.3rem;
    }

    @media print {
      display: none;
    }
  }

  &__guide {
    font-weight: bold;
    font-size: 4rem;
    line-height: 5.2rem;
    color: color(primary_grey);
    margin-bottom: 2.8rem;
    text-align: center;

    @media print {
      font-size: 2.8rem;
      margin-bottom: 1rem;
    }
  }

  &__card {
    background: #FFFFFF;
    border: 2px dashed #E3E5E3;
    box-sizing: border-box;
    border-radius: 1rem;
    padding: 2rem 2.1rem 4.2rem 1.5rem;
    color: black;
    font-size: 1.4rem;
    line-height: 2.4rem;
    position: relative;
    display: flex;
    flex-direction: column-reverse;

    &--for-blob {
      padding: 15rem 2.1rem 4.2rem 1.5rem !important;
    }

    @include iota-breakpoint(md) {
      flex-direction: row;
      padding: 2.4rem 3.2rem 2.5rem 3.4rem;
      font-size: 1.6rem;
      line-height: 2.6rem;
    }

    &.card-padding {
      padding: 5rem 2.1rem 4.2rem 1.5rem;
    }

    @media print {
      flex-direction: row;
      padding: 5rem 2rem 2rem 3rem;
      font-size: 1.6rem;
      line-height: 2.8rem;
    }
  }

  &__logo {
    position: absolute;
    top: 2rem;
    left: 1.9rem;
    display: flex;
    align-items: center;

    &--for-blob {
      top: 7rem;
    }
    
    >img:first-child {
      height: 5.7rem;
      top: 7rem;
    }

    >img:nth-child(2) {
      padding-left: .4rem;
      height: 1.5rem;
      transform: translateY(.4rem);
    }

    @media print {
      top: 4rem;
      left: 3.4rem;
    }
  }

  &__price {
    font-weight: 800;
    font-size: 2.8rem;
    line-height: 3.6rem;

    @include iota-breakpoint(md) {
      font-size: 6rem;
      line-height: 7.2rem;
      margin-top: .5rem;
      margin-bottom: .4rem;
    }

    @media print {
      font-size: 3rem;
      line-height: 4rem;
    }
  }

  &__drugname {
    font-weight: 600;
    margin-top: 2rem;
    font-size: 2.4rem;
    line-height: 2.4rem;
    overflow-wrap: break-word;

    @include iota-breakpoint(md) {
      font-weight: bold;
      font-size: 4rem;
      line-height: 5.2rem;
      margin-top: 2.6rem;
    }

    @media print {
      font-size: 2.6rem;
      line-height: 3rem;
      margin-top: 1.1rem;
    }
  }

  &__filter {
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin-top: 1.1rem;

    >span:first-child {
      padding-right: 1rem;
      margin-right: 1rem;
      border-right: .1rem solid black;
    }

    @include iota-breakpoint(md) {
      margin-top: .5rem;
      font-size: 2rem;
      line-height: 3.2rem;
    }

    @media print {
      line-height: 3.2rem;
      margin-top: 0;
    }
  }

  &__reminder {
    margin-top: 1.9rem;

    @include iota-breakpoint(md) {
      margin-top: 3.5rem;
      font-size: 1.4rem;
      line-height: 2.4rem;
    }

    @media print {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }

  &__ddn {
    margin-top: 2rem;
    opacity: .75;

    a {
      text-decoration: underline;
    }
  }

  &__drug {
    padding: 2.6rem 0 0;

    @include iota-breakpoint(md) {
      padding: 0 5.9rem 0 .5rem;
      max-width: 500px;
    }

    @media print {
      padding: 1rem 2rem 0 0;
    }

    &--iframe {
      @include iota-breakpoint(md) {
        padding: 2.6rem 5.9rem 0 .5rem;
      }

      @media print {
        padding: 2rem 2rem 0 0;
      }
    }
  }

  &__discount {
    min-width: 100%;
    background-color: #F8F8F8;
    border-radius: 1rem;
    padding: 1.6rem 2.1rem 2.8rem 2.2rem;
    font-size: 1.6rem;
    line-height: 2.6rem;

    @include iota-breakpoint(md) {
      font-size: 2rem;
      line-height: 3.2rem;
      margin-top: 0;
      min-width: 33.4rem;
      max-width: 35.4rem;
      padding: 2.7rem 2.8rem 2.8rem 2.2rem;
    }

    @media print {
      min-width: 30rem;
      max-width: 50%;
      margin-top: 0;
    }

    &--iframe {
      margin-top: 0;
    }
  }

  &__pharmacy-use {
    font-size: 2.4rem;
    line-height: 3.2rem;
    margin-bottom: 2.2rem;
  }

  &__discount-numbers {
    margin-bottom: 1.2rem;

    &__value {
      text-transform: uppercase;
    }

    @include iota-breakpoint(md) {
      margin-bottom: 2.3rem;
    }

    >div {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include iota-breakpoint(md) {
        justify-content: space-between;
      }

      @media print {
        justify-content: space-between;
      }

      >div:first-child {
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 1.2rem;
        width: 14rem;

        @include iota-breakpoint(md) {
          font-size: 1.6rem;
          width: auto;
        }
      }

      &:not(:last-child) {
        margin-bottom: .5rem;

        @include iota-breakpoint(md) {
          margin-bottom: .5rem;
        }
      }
    }
  }

  &__attention {
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin-bottom: 1.0rem;

    @include iota-breakpoint(md) {
      margin-bottom: 2.2rem;
    }
  }

  &__contact {
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}

.c-coupon-send {
  padding: 0 0 2rem;

  @include iota-breakpoint(md) {
    padding: 1.6rem 0 2rem;
  }

  @media print {
    display: none;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media print {
      display: none;
    }

    >button {
      margin: 0 auto;
      padding: 1.3rem 1.8rem 1.4rem;
      height: 5.1rem;
      max-width: 16rem;

      >.anticon {
        margin-right: .4rem;
      }

      &:not(:last-child) {
        margin-bottom: 1.6rem;
      }
    }

    @include iota-breakpoint(md) {
      flex-direction: row;

      >button:not(:last-child) {
        margin-right: .8rem;
        margin-bottom: 0;
      }
    }
  }
}

.c-coupon-modal {
  background-color: white;
  border-radius: 1rem;
  padding: 2.4rem 3rem;

  @include iota-breakpoint(md) {
    padding: 2.4rem 2.6rem 2.9rem 3rem;
  }
}

.c-coupon-form {
  &__title {
    font-weight: bold;
    font-size: 2.8rem;
    line-height: 3.6rem;
    color: color(primary_grey);
    margin-bottom: .6rem;
  }

  &__sub-title {
    font-size: 1.8rem;
    line-height: 2.6rem;
    margin-bottom: 1.2rem;

    @include iota-breakpoint(md) {
      margin-bottom: 2rem;
    }
  }

  &__submit {
    padding: 1.3rem 1.6rem;
  }

  &__footer {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #001322;
    margin-top: 2.7rem;

    a {
      text-decoration: underline !important;

      &:hover {
        text-decoration: none !important;
      }
    }
  }

  .ant-row {
    &.ant-form-item {
      margin-bottom: 1.2rem;
    }

    .ant-form-item {
      margin-bottom: .8rem;
    }

    &:first-child .ant-form-item {
      margin-bottom: 2.4rem;

      @include iota-breakpoint(md) {
        margin-bottom: 3.2rem;
      }
    }
  }

  .ant-checkbox-wrapper {
    margin-top: 1.2rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

.c-coupon-form__submit {
  &.m-right {
    margin-right: 1rem;
  }
}
