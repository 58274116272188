.c-search {
  &__faqs {
    padding: 5rem 1.6rem 3.8rem;

    @include iota-breakpoint(md) {
      padding: 5rem 13.2rem 6.5rem;
    }

    .c-heading {
      margin-bottom: 2.2rem;
    }
  }
}

.c-search-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;

  &--iframe {
    margin: 1rem 0;
  }

  &__logos-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
    padding-top: 3rem;

    @media (min-width: 768px) {
      padding-bottom: 10rem;
    }
  }

  &__logos {
    margin-top: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

    >img {
      margin: 0 .8rem 1.2rem;

      @include iota-breakpoint(lg) {
        margin: 0 1.2rem 1.2rem;
      }
    }

    @include iota-breakpoint(lg) {
      margin-top: 2.2rem;
      margin-bottom: -7rem;
    }
  }

  &__intro {
    font-size: 4rem;
    line-height: 5rem;
    font-weight: 800;
    max-width: 35rem;
    text-align: center;
    padding: 0;
    color: color(primary_grey);
    margin-bottom: 1.5rem;

    >span {
      color: color(link);
    }

    @include iota-breakpoint(lg) {
      font-size: 6rem;
      line-height: 7.2rem;
      max-width: 82rem;
    }
  }

  &__free {
    text-align: center;
    font-size: 2.4rem;
    line-height: 3.6rem;
    font-weight: 400;
    margin-bottom: 2.2rem;
    padding: 0 .5rem;

    @include iota-breakpoint(lg) {
      margin-bottom: 4.4rem;
    }
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.c-search-input {
  height: 9.2rem;
  width: 100%;
  border: 1px solid #001322;
  box-sizing: border-box;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.09);
  border-radius: 1rem;
  background-color: white;
  display: flex;
  padding: 1.6rem 1.7rem;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &--sm {
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    border: none;
    box-shadow: none;
    padding: 0;
    background-color: transparent;

    .c-search-input__drug,
    .c-search-input__brandindicator,
    .c-search-input__zipcode {
      padding: 0;
      flex: none;
      width: calc(100% - 2.8rem);
      padding: 1.7rem 1.5rem;
      margin-bottom: 1.8rem;
      border: 1px solid #001322;
      box-sizing: border-box;
      box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.09);
      border-radius: 10px;
      background-color: color(white);

      @include iota-breakpoint(sm) {
        width: calc(100% - 10rem);
      }
    }

    .c-search-input__brandindicator {
      padding: 1rem 1.2rem 0.3rem
    }

    .c-search-input__drugnames {
      top: 7.6rem;
      left: 1.4rem;
      width: calc(100% - 2.8rem);
      z-index: 2;

      @include iota-breakpoint(sm) {
        left: 5rem;
        width: calc(100% - 10rem);
      }
    }
  }

  &--results {
    border: none;
    box-shadow: none;
    padding: 0;

    .c-search-input__drug,
    .c-search-input__brandindicator,
    .c-search-input__zipcode {
      border: 1px solid #A7A8AA;
      box-sizing: border-box;
      border-radius: 1rem;
      padding: 1.5rem 1.9rem 1.4rem;
      background-color: color(white);
      margin-right: 1.2rem;
    }

    .c-search-input__brandindicator {
      padding: 1.2rem 1.3rem 0.3rem
    }

    .c-search-input__drugnames {
      top: 10.4rem;
      left: 2.4rem;
      width: calc(100% - 4.8rem);
      z-index: 2;

      @include iota-breakpoint(sm) {
        width: calc(100% - 4.8rem);
      }
    }

    .c-search-input__label {
      margin-bottom: .9rem;
    }

    .c-search-input__brandindicator_label {}
  }

  &--results-sm {
    padding: 0;
    height: 19.1rem;

    .c-search-input__drug {
      margin-right: 0;
      margin-bottom: 1.5rem;
      width: 100%;
    }

    .c-search-input__zipcode {
      margin-right: 1rem;
      margin-bottom: 0;
      width: calc(100% - 8rem);
    }

    .c-search-input__brandindicator {
      margin-right: 1rem;
      margin-bottom: 0;
      width: calc(100% - 8rem);
    }

    .c-search-input__drugnames {
      top: 9.6rem;
      left: 1.5rem;
      width: calc(100% - 3rem);
      z-index: 2;
    }
  }

  &__zip-btn {
    display: flex;
    width: 100%;
  }

  &__lookup-btn {
    height: 7rem;
    width: 7rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .anticon-search>svg {
      width: 1.4em;
      height: 1.4em;
    }

    .ant-spin {
      right: 50%;
      transform: translate(50%, -50%);
    }
  }

  &__drug {
    padding: 0 1rem;
    flex: 2;
  }

  &__zipcode {
    padding: 0 2.2rem;
    flex: 1;
    border-left: .1rem solid black;
  }

  &__label {
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.2rem;
    margin-bottom: .5rem;
  }

  &__title {
    display: block;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.2rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  &__drugnames {
    z-index: 1;
    position: absolute;
    top: 10rem;
    left: 0;
    width: 100%;
    background-color: color(white);
    border: 1px solid #F8F8F8;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    padding: 2.4rem 1.6rem;
    min-height: 10rem;
    max-height: 44.8rem;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &--iframe {
      max-height: 15.8rem;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__drugname {
    font-size: 1.6rem;
    line-height: 4rem;
    padding: 0 .8rem;
    border-radius: .5rem;
    cursor: pointer;

    &:hover {
      background-color: color(screen_bg);
    }
  }

  &__dropdown-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(color(content_bg), 0.3);
  }
}

.c-search-steps {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6.2rem;
  padding-bottom: 6.4rem;

  @include iota-breakpoint(md) {
    padding-top: 9.5rem;
    padding-bottom: 13.9rem;
  }

  &__title {
    font-weight: bold;
    font-size: 4rem;
    line-height: 5.2rem;
    min-width: 36rem;
    color: color(primary_grey);
    text-align: center;

    >span {
      color: color(link);
    }
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include iota-breakpoint(md) {
      flex-direction: row;
    }
  }
}

.c-search-step {
  position: relative;
  border: 1px solid #E3E5E3;
  border-radius: 1rem;
  width: 30rem;
  padding: 7.5rem 1.6rem 2.9rem 1.6rem;
  margin-top: 7.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;

  @include iota-breakpoint(md) {
    margin-top: 8.8rem;

    &:not(:last-child) {
      margin-right: 2rem;
    }
  }

  @include iota-breakpoint(lg) {
    &:not(:last-child) {
      margin-right: 4rem;
    }
  }

  &__number {
    position: absolute;
    top: -5.6rem;
    left: 9.4rem;
    width: 11.2rem;
    height: 11.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 4rem;
    line-height: 2.4rem;
    color: color(primary_grey);
  }

  &__title {
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
    line-height: 3.2rem;
    margin-bottom: .8rem;
  }

  &__desc {
    text-align: center;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

.fsm {
  background: #fff;
  padding: 5rem;
  border-radius: 2rem;
  margin-bottom: 15rem;

  h1 {
    color: #42454a;
    text-align: center;
    font-size: 3rem;
  }

  &__meds {
    display: block;
    text-align: center;

    @include iota-breakpoint(md) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      margin-top: 3rem;
      text-align: left;
    }

    &__med {
      padding: 1.5rem;
      font-size: 1.6rem;

      a {
        text-decoration: underline;
        color: #629A35;
        font-weight: 600;

        &:hover {
          color: #32501b;
          transition: 0.2s;
        }
      }
    }
  }
}

.c-error-modal {
  background-color: white;
  border-radius: 1rem;

  h1 {
    color: #42454a;
    font-size: 3rem;
  }

  p {
    font-size: 1.8rem;
  }

  .ant-modal-footer {
    border-top: 0;
  }

  input {
    box-shadow: none !important;
  }
}

.cookie-consent-banner {
  .cookie-consent-content {
    align-items: center;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 1rem;
    width: 100%;
    border: 1px solid;
    padding: 16.5px 24px;
    box-shadow: 0 -1px 10px 0 #acabab4d;
    background-color: #FFFFFF;
    border-color: #f4f4f4;
    color: #212121;
    z-index: 9999;

    a {
      color: rgb(24, 99, 220);
      text-decoration: underline;
      margin-left: .5rem;
    }

    button {
      font-size: 14px;
      font-family: inherit;
      line-height: 24px;
      padding: 8px 27px;
      font-weight: 500;
      margin: 0 8px 0 0;
      border-radius: 2px;
      white-space: nowrap;
      cursor: pointer;
      text-align: center;
      text-transform: none;
      min-height: 0;
      background: #1863dc;
      color: #ffffff;
      border: 2px solid #1863dc;
      margin-left: auto;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
