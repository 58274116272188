.c-results-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.6rem 0 0;

  &--iframe {
    margin-bottom: 5rem;

    @include iota-breakpoint(md) {
      margin-bottom: 10rem;
    }
  }

  >div {
    width: 100%;

    @include iota-breakpoint(md) {
      width: $search-width;
    }
  }

  &__drugname {
    font-weight: bold;
    font-size: 32px;
    line-height: 42px;
    color: color(primary_grey);
    margin-top: 3.5rem;
    overflow-wrap: break-word;
  }

  &__set-dosage {
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 1.2rem;
    text-transform: uppercase;
    color: color(primary_grey);
    margin-top: 3.4rem;
  }

  &__set-desc {
    font-weight: normal;
    font-size: 1.8rem;
    line-height: 2.6rem;
    margin-top: 0.8rem;
    padding-bottom: 1.9rem;
    border-bottom: 1px solid #c8c9c7;
  }

  &__inputs {
    display: flex;
    font-weight: normal;
    font-size: 18px;
    line-height: 16px;
    color: #000000;
    margin-top: 0.9rem;

    >div:not(:first-child) {
      padding-left: 1rem;
    }

    >div:not(:last-child) {
      padding-right: 1rem;
      border-right: 1px solid black;
    }
  }

  &__edit-show {
    margin-top: 1.7rem;
    width: 100%;

    >span:first-child {
      margin-right: 0.5rem !important;
    }
  }

  &--back {
    cursor: pointer;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: color(primary_green);
    margin-right: auto;
  }
}

.c-results-filters {
  display: flex;
  justify-content: flex-start;
  margin-top: 1.9rem;
  flex-direction: column;
  position: relative;

  @include iota-breakpoint(md) {
    flex-direction: row;
  }

  >div {
    margin-bottom: 1.6rem;

    @include iota-breakpoint(md) {
      margin-right: 1.4rem;
      margin-bottom: 0;
    }
  }

  .c-results-filter_customquantity {
    padding-top: .7rem;
    padding-left: 1.3rem;
    padding-right: 1.3rem;
  }

  .g-select__close {
    position: absolute;
    top: 3.2rem;
    right: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    // background: #f3c3c3;
    display: flex;
    cursor: pointer;
    z-index: 1;
  }

  .g-select__checkbox {
    position: absolute;
    top: 3.2rem;
    right: 4rem;
    width: 2.5rem;
    height: 2.5rem;
    // background: #f3c3c3;
    display: flex;
    cursor: pointer;
    z-index: 1;
  }
}

.c-results {
  width: 100%;

  @include iota-breakpoint(md) {
    margin: 0 auto;
    width: $search-width;
  }

  &__count {
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: black;
    margin-top: 2.8rem;
  }

  &__container {
    margin: 2.1rem 0 2.5rem;
    padding: 0;
  }

  &__title {
    font-size: 2.4rem;
    line-height: 2.4rem;
    color: black;
    font-weight: 600;
    margin-bottom: 2.6rem;
    display: flex;
    align-items: center;

    >img {
      width: 1.9rem;
      margin-right: 1rem;
    }
  }

  &__item {
    background: #ffffff;
    border: 1px solid #c8c9c7;
    border-radius: 1rem;
    padding: 2rem 2.4rem 2.2rem;

    &:not(:last-child) {
      margin-bottom: 1.6rem;
    }

    &--sm {
      padding: 1rem;
    }
  }

  &__toggle-btn {
    margin-top: 1.2rem;
    color: color(link) !important;

    &--invisible {
      opacity: 0;
      cursor: auto !important;
    }
  }

  &__top-wrap {
    display: flex;
    align-items: center;

    &--sm {
      display: flex;

      >div {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        &:first-child {
          align-items: center;
        }

        &:nth-child(2) {
          display: block;
          margin-left: auto;
        }
      }

      .c-results__price {
        margin-right: 0;
        flex: 1;
      }

      .c-results__locations {
        >div:first-child {
          margin-top: 0;
        }
      }
    }
  }

  &__pharmacy-wrap {
    flex: 1;
  }

  &__discount-btn-wrap {
    min-width: 14.3rem;

    &__before {
      display: block;
      font-size: 12px;
      margin: 0;
    }
  }

  .strikethrough {
    text-decoration: line-through;
  }

  &__discount-btn {
    padding: 1.2rem 1.5rem 1.4rem;
  }

  @media (max-width: 992px) {
    &__discount-btn {
      padding: 0.5rem 1rem;
      float: right;
      min-width: 125px;
      min-height: 55px;

      &--savings {
        padding: 0 1rem 0.5rem;
      }
    }
  }

  &__pharmacyname {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.4rem;
  }

  &__locations {
    &--multi {
      margin-top: 2rem;
    }

    >div {
      font-weight: normal;
      font-size: 1.6rem;
      line-height: 2.6rem;
      color: #000000;
      margin-top: 0.6rem;

      &:not(:first-child) {
        margin-top: 1.4rem;
      }
    }
  }

  &__location {
    display: flex;
  }

  &__prices {
    display: flex;
    align-items: center;

    .c-results__price {
      color: #000000;
      margin-right: 3.6rem;

      >p:first-child {
        text-align: right;
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 1.2rem;
        // margin-bottom: 0.7rem;
      }

      >p:last-child {
        text-align: right;
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 2.4rem;
        color: color(primary_grey);
      }
    }

    .c-results__before-price {
      color: #000000;
      margin-right: 3.6rem;

      >p:first-child {
        text-align: right;
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 1.2rem;
        margin-bottom: 0.7rem;
        opacity: 0.5;
        text-decoration: line-through;
      }

      >p:last-child {
        text-align: right;
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 1.2rem;
        opacity: 0.75;
        color: green;
      }
    }
  }
}

@media (max-width: 992px) {
  .c-results__before-price {
    display: none;
  }
}

.no-iframe {
  display: none;
}
